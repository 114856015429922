import { Theme, ThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { ColorThemeProps } from '../types/theme.types';

const BASE_FONT_SIZE = 14;

export const createTheme = (themeColors: ColorThemeProps, isDarkMode: boolean): Theme => {
  const theme: ThemeOptions = {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: BASE_FONT_SIZE,
            borderBottom: 'none',
            fontWeight: 500,
            padding: '10px 20px'
          },
          head: {
            color: themeColors.text?.primary,
            fontWeight: 600,
            borderTop: `1px solid ${themeColors.borderColor}`,
            borderBottom: `1px solid ${themeColors.borderColor}`
          },
          alignRight: {
            color: themeColors.text?.primary
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            alignRight: {
              color: themeColors.text?.primary
            }
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${themeColors.borderColor}`,
            alignLeft: {
              color: themeColors.text?.primary,
              padding: '7.5px 20px'
            },
            alignRight: {
              color: themeColors.text?.primary,
              padding: '7.5px 20px'
            }
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          className: 'input-form',
          size: 'small'
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary'
        },
        styleOverrides: {
          root: {
            fontSize: BASE_FONT_SIZE
          }
        }
      }
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: BASE_FONT_SIZE,
      h1: {
        fontSize: 30,
        fontWeight: 400,
        color: themeColors.textTitle.primary
      },
      subtitle1: {
        fontSize: 12,
        fontWeight: 400,
        color: themeColors.textTitle.primary
      },
      h2: {
        fontSize: 18,
        color: themeColors.primary.main,
        fontWeight: 500
      },
      h3: {
        fontSize: 14,
        color: themeColors.text?.primary,
        marginBottom: 20,
        fontWeight: 500
      },
      h4: {
        fontSize: 14,
        color: themeColors.text?.primary,
        fontWeight: 500
      }
    },
    palette: {
      primary: themeColors.primary,
      secondary: themeColors.secondary,
      background: themeColors.background,
      menu: themeColors.menu,
      menuActive: themeColors.menuActive,
      menuHover: themeColors.menuHover,
      navBar: themeColors.navBar,
      borderColor: themeColors.borderColor,
      addFieldButton: themeColors.addFieldButton,
      text: themeColors.text,
      scrollbarThumb: themeColors.scrollbarThumb || '#bdbdbd',
      scrollbarBackground: themeColors.scrollbarBackground || 'transparent',
      mode: isDarkMode ? 'dark' : 'light',
      action: themeColors.action
    }
  };
  return createMuiTheme(theme);
};
